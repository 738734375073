.order__item--totalprice {
  font-weight: 700;
}

.article .a-col .a-block h1,
.article .a-col .a-block h2,
.article .a-col .a-block h3 {
  color: $color3;
}

.article {
  .article-list .article-list-item .a-col {
    border: none;
  }

  .cms-article_social_profile {
    border-color: $colorBg10;
  }

  .read-more {
    text-transform: initial;
    font-size: 14px;
    color: $color3;
    @media (--mobile) {
      font-size: 12px;
    }
  }

  .read-more-arrow {
    border-radius: 6px;
  }

  @media (--tablet-and-above) {
    background-color: $colorBg1;
  }
  @media (--mobile) {
    background-color: $color5;
  }

  .main-container {
    @media (--tablet-and-above) {
      background-color: $colorBg1;
    }
    @media (--mobile) {
      background-color: $color5;
    }
  }

  &__campaign-period {
    > * {
      background-color: $color6;
      color: $color5;
      text-transform: initial;
      font-weight: 700;
    }
  }

  &__introduction h1 {
    color: $color3;
  }

  &__ingress {
    letter-spacing: 0;
    word-spacing: 0;
    font-weight: 700;
  }

  &__author__name__date {
    text-transform: initial;
    font-size: 12px;
  }

  &__blockquote {


    @media (--tablet-and-above) {
      margin: 2px 10px 0 0;
    }

    &__text {
      font-weight: 700;
      @media (--tablet-and-above) {
        width: calc(100% - 67px);
        font-size: 28px;
      }
      @media (--mobile) {
        font-size: 24px;
      }
    }
  }

  &__guide,
  &__factbox {

    .heading,
    .heading--1,
    .heading--2,
    .heading--3,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    ul,
    ol,
    p,
    a,
    a:hover,
    a:focus {
      color: var(--standard-text-color);
    }


    .icon-factbox,
    .icon-guide {
      background-color: $color1;
    }
  }

  &__step-by-step li::before {
    border-radius: 14px;
    background-color: $color1;
    font-weight: var(--font-weight-bold);
  }

  &__points,
  &__step-by-step {
    border-color: $colorBg1;
  }

  &__offer-banner {
    background-color: $color1;
    color: $color5;

    h2,
    h3,
    p {
      color: $color5;
    }

    .btn {
      background-color: var(--highligted-background-color);

      &:hover {
        background-color: color-mix(in srgb, var(--highligted-background-color), #fff 5%)
      }
    }

    &__splash {
      fill: $color6;

      &__text {
        color: $color5;
        text-transform: initial;
        right: 52px;
        top: 30px;
        font-weight: 700;
        @media (--tablet) {
          right: 47px;
        }
      }
    }
  }
}

.cms-faqblock {
  background-color: $colorBg1;

  .faq__icon {
    border-radius: 10px;
    @media (--mobile) {
      border-radius: 6px;
    }
  }
}

.article {

  .cms-productlist,
  .cms-faqblock,
  .cms-clerk,
  .cms-partnerlist,
  .cms-productlist {
    @media (--mobile) {
      background-color: $colorBg1;
    }
  }
}
