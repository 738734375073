.landingpage {
  .productlist--view-more {
    .btn-brand {
      background-color: $color1;
      color: $color5;
      border-color: $color5;
      &:hover {
        background-color: $color5;
        color: $color1;
      }
    }
  }
}

.cms-halfwidth {
  .half-width__wrapper {
    .btn-brand {
      background-color: transparent;
      border-color: $color5;
      color: $color5;
      &:hover {
        background-color: $color5;
        color: $color1;
      }
    }
    .btn-brand:hover {
      color: $color9;
    }

    &__style3 {
      .btn-brand:hover,
      :active,
      :focus {
        color: var(--offset-of-brand-color-darker);
      }
    }

    &__style-standard {
      .btn-brand {
        border: none;
        background: $color1;
        color: $color5;
        &:hover {
          background-color: $color10;
          color: $color5;
        }
        &:active {
          color: $color5;
          outline: none;
        }
        &:focus {
          color: $color5;
          outline: none;
        }
      }
    }
  }
}
