// Colors
$color1: #50c877;
$color2: #e4f8f0; //LightGreen
$color3: #363636; //Black
$color4: #e8f1d7; //LightGreen
$color5: #ffffff; //White
$color6: #e84855; //third color
$color7: #787979; //hover black
$color8: #2aa172; //secondary main color
$color9: #2aa172; //primary hover of main color
$color10: #2aa172; //secondary hover of main color
$color11: #363636; // black

// Backgrounds
$gColorbg1: #363636;
$colorBg1: #eeeeee; // Lightgrey - Body color - Primary grey
$colorBg2: #ffffff; // White - Product bg
$colorBg3: #ebebeb; // Grey - aside filters
$colorBg4: #ffe787; //offer yellow
$colorBg5: #7ea832; //btn hover gradient
$colorBg6: #96c300; // green
$colorBg7: #363636; // black
$colorBg8: #96c300; // green
$colorBg9: #a3d404; // lighter green
$colorBg10: #e1e1e1; // Secondary grey
$colorBg11: #dedede; // Tertiary grey

// Borders
$colorBorder1: #d6d5d5; // Grey
$colorBorder2: #d9d9d9; // Dark(er) grey
$colorBorder3: #c6c6c6; // Dark grey (search inupt)
$colorBorder4: #2e87ab; // Lightblue
$colorBorder5: #363636; // Darkest
$colorBorder6: #363636; // Darkest
$colorBorder11: #aeead2; // Very light green

$borderThin: 1px solid;
$borderMedium: 2px solid;
$borderThick: 3px solid;

// Font
$colorFont1: #363636; // primary - grey
$colorFont2: #363636; // blackish
$colorFont3: #ffffff; // White (productlist btn)
$colorFont4: #666666; // lightgrey - footer usp  hover on links

$fontFamilyOswald: museo-sans, sans-serif;
$fontFamilyArial: museo-sans, sans-serif;
$gFontFamilyArial: museo-sans, sans-serif;
