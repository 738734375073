.aa-suggestion > a {
  color: $color3;
}

.aa-datasets-not-products .aa-dataset-brands a,
.aa-datasets-not-products .aa-dataset-groups a {
  text-transform: initial;
}

.aa-dataset-products,
.aa-datasets-not-products {
  background-color: $colorBg1;
}

.aa-datasets-not-products {
  @media (--tablet-and-above) {
    border-left: var(--border-thin) var(--white);
  }
}

.aa-datasets-not-products .aa-dataset-brands .aa-suggestion > a,
.aa-datasets-not-products .aa-dataset-groups .aa-suggestion > a {
  background-color: var(--white);
  text-transform: none;
  font-weight: 700;
  &:hover {
    color: $color3;
  }
}

.aa-suggestion em {
  font-weight: 700;
  color: $color3;
  @media (--tablet) {
    font-size: 0.8em;
  }
}

.aa-suggestion {
  background-color: $color5;
  border-color: $color5;
}

.aa-datasets-not-products .aa-dataset-brands .js-show-all,
.aa-datasets-not-products .aa-dataset-groups .js-show-all {
  &:hover {
    text-decoration: underline;
  }
}

.aa-no-hits {
  padding: 15px 20px;
  margin: -10px -15px;
  background-color: $color5;
}

.aa-dropdown-menu__overlay {
  top: 70px;
  @media (--mobile) {
    top: 53px;
  }
}
