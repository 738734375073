body {
  color: $colorFont1;
  font-family: museo-sans, Arial, sans-serif;
  font-size: 16px;
  background-color: $colorBg1;
}
.main-container {
  background-color: $colorBg1;
}
.productcard {
  .main-container {
    background-color: $color5;
  }
}

.form-control {
  font-size: 0.9rem;
  border-radius: 8px;
  height: 46px;
}

input:-internal-autofill-selected {
  background-color: rgba(80, 200, 119, 0.1) !important;
}

a,
a:focus {
  color: $color3;
  &:hover {
    color: #000;
  }
}

.bg-light-blue {
  background-color: $colorBg1;
}

.text-uppercase {
  text-transform: initial !important;
}

// Inputs
input:focus {
  outline: none;
  border-color: $color1;
  background-color: $color5 !important;
}

.divider {
  border-color: $gColorBorder1;
  &--light {
    border-color: $gColorBorder1;
  }
  &--elements {
    border-bottom: var(--border-thin) $gColorBorder1;
  }
}

.drop-down {
  border-color: $color5;

  &:focus {
    border-color: $color1;
  }
  &__see-more-header {
    font-size: 14px;
    border-color: $gColorBorder1;
    border-radius: 8px;
    @media (--mobile) {
      font-size: 10px;
      border-radius: 6px;
    }
  }
  &__header {
    @media (--mobile) {
      font-size: 12px;
    }
    &__selected {
      @media (--mobile) {
        font-size: 12px;
      }
    }
  }
  &__wrapper {
    font-size: 15px;
    text-transform: none;
    @media (--mobile) {
      font-size: 12px;
    }
  }
  &__arrow {
    fill: $color3;
  }
}


h1,
h2,
h3,
h4,
h5,
h6,
.heading,
.heading--1,
.heading--2,
.heading--3,
.heading--4,
.heading--5 {
  font-family: museo-sans, sans-serif;
  color: $color3;
  font-weight: 700;
  text-transform: initial !important;
  letter-spacing: 0 !important;
  word-spacing: 0 !important;
}

h1,
.heading--1 {
  font-size: 45px;
  line-height: 55px;
  @media (--tablet-lg) {
    font-size: 43px;
    line-height: 53px;
  }
  @media (--tablet-sm) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (--mobile) {
    font-size: 35px;
    line-height: 43px;
  }
  @media (--mobile-sm) {
    font-size: 33px;
    line-height: 39px;
  }
}

h2,
.heading--2 {
  font-size: 35px;
  line-height: 45px;
  @media (--tablet-lg) {
    font-size: 33px;
    line-height: 43px;
  }
  @media (--tablet-sm) {
    font-size: 30px;
    line-height: 38px;
  }
  @media (--mobile) {
    font-size: 27px;
    line-height: 35px;
  }
  @media (--mobile-sm) {
    font-size: 25px;
    line-height: 31px;
  }
}

h3,
.heading--3 {
  font-size: 26px;
  line-height: 36px;
  @media (--tablet-lg) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (--tablet-sm) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (--mobile) {
    font-size: 20px;
    line-height: 28px;
  }
  @media (--mobile-sm) {
    font-size: 18px;
    line-height: 24px;
  }
}

h4,
.heading--4 {
  font-size: 20px;
  line-height: 30px;
  @media (--tablet-lg) {
    font-size: 19px;
    line-height: 29px;
  }
  @media (--tablet-sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (--mobile) {
    font-size: 17px;
    line-height: 25px;
  }
  @media (--mobile-sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

h6,
.heading--6,
h5,
.heading--5 {
  font-size: 16px;
  line-height: 26px;
  @media (--tablet-lg) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (--tablet-sm) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (--mobile) {
    font-size: 13px;
    line-height: 21px;
  }
  @media (--mobile-sm) {
    font-size: 12px;
    line-height: 18px;
  }
}

p {
  @media (--desktop) {
    font-size: 16px;
    line-height: 26px;
  }
}

.heading--underlined {
  border-color: $gColorBorder1;
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-indicator {
    background-color: $gColorFont3;
    border-color: $color3;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23363636'/%3E%3C/svg%3E");
  }
}

.form-control {
  border-color: $gColorBorder1;
}

.form-control:focus {
  box-shadow: 0 0 0 0 rgba(80, 200, 119, 0);
  border-color: $color1;
}
.breadcrumb {
  &__link {
    text-transform: initial;
    font-size: 13px;
    &:hover {
      color: $color3;
    }
  }
  &__list:last-child {
    font-weight: 700;
  }
  &__list {
    li.breadcrumb__item:last-of-type {
      text-decoration: underline;
      .breadcrumb__link {
        color: $color3;
      }
    }
  }

  &__item {
    font-size: 13px;
  }
  &__item a:focus {
    color: $color3;
    text-decoration: underline;
  }
}

.product-stock {
  &__status--in_stock {
    background-color: $color1;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    box-shadow: rgba(38, 196, 133, 0.2) 0px 0px 0px 3px;
  }
}
