#product-card {
  .product-card {
    &__enlarged {
      .drop-down {
        border-color: $gColorBorder1;
      }
    }
    &__maininfo {
      .drop-down {
        &:hover,
        &:focus {
          border-color: $color1;
        }
      }
      &--not-for-sale {
        .product-card__not-for-sale {
          font-weight: 700;
          background-color: $colorBg1;
          border-radius: 0 0 14px 14px;
          padding: 15px 15px 40px 15px;
          @media (--mobile) {
            padding: 10px 10px 35px 10px;
          }
          &__wrapper {
            border-radius: 0;
            margin: 0;
          }
        }
      }
    }
    &__product-name {
      font-size: 35px;
      line-height: 45px;
      @media (--tablet-lg) {
        font-size: 33px;
        line-height: 43px;
      }
      @media (--tablet-sm) {
        font-size: 30px;
        line-height: 38px;
      }
      @media (--mobile) {
        font-size: 27px;
        line-height: 35px;
      }
      @media (--mobile-sm) {
        font-size: 25px;
        line-height: 31px;
      }
    }
  }
}
.product-card {
  &__climate-friendly__sm {
    bottom: 31px;
  }
  &__thumbnail {
    border-color: $color5;
    &:hover {
      border-color: $color1;
    }
    &__active {
      border-color: $color1;

      &:hover {
        border-color: $color1;
      }
    }
  }
  &__recommendations {
    font-size: 16px;

    @media (--tablet-sm) {
      font-size: 14px;
    }
    @media (--mobile) {
      font-size: 14px;
    }
    &__count {
      font-size: 14px;
      margin-bottom: -1px;
      @media (--tablet-sm) {
        font-size: 12px;
      }
      @media (--mobile) {
        font-size: 12px;
      }
    }
  }

  &__product-name,
  &__current-price,
  &__content__header__h2 {
    color: $color3;
  }
  &__content__header__h2,
  &__current-price {
    font-size: 35px;
    line-height: 45px;

    @media (--tablet-lg) {
      font-size: 33px;
      line-height: 43px;
    }
    @media (--tablet-sm) {
      font-size: 30px;
      line-height: 38px;
    }
    @media (--mobile) {
      font-size: 24px;
      line-height: 30px;
    }

    @media (--mobile-sm) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  &__part-of-bundle__tag,
  &__volume-discount__tag {
    background-color: var(--brand-color);
    border-color: var(--brand-color);
    color: var(--white);
    height: auto;
    @media (--tablet-and-above) {
      font-size: 15px;
    }
  }


  &__current-price {
    font-weight: 700;
    color: $color3;
  }

  &__offer-expiry {
    text-transform: initial;
    font-size: 14px;
  }
  &__variants {
    text-transform: initial;
    font-weight: 700;
    font-size: 16px;
    @media (--mobile) {
      font-size: 14px;
    }
    &__color {
      border-color: $colorBg1;
    }
    &__selected {
      &__name,
      &__description {
        font-weight: 300;
        font-size: 14px;
        @media (--mobile) {
          font-size: 12px;
        }
      }
    }
  }

  &__CTA {
    &__add-to-basket {
      background-color: $color1;
      font-weight: 700;
      border-radius: 14px;

      @media (--desktop) {
        width: calc(100% - 140px);
      }
      @media (--tablet-lg) {
        width: calc(100% - 140px);
      }
      @media (--mobile) {
        font-size: 18px;
      }
      svg.cart {
        stroke: $color5;
        height: 22px;
        width: 22px;
        top: 19px;
        @media (--mobile) {
          height: 19px;
          width: 19px;
          top: 21px;
        }
      }
      &:hover {
        background-color: $color9;
      }
    }
  }

  &__product-image__energy-label span {
    font-weight: 700;
  }
  &__product-image__dot button {
    border-color: $gColorBorder1;
  }
  &__product-image__dot--active button {
    border-color: $color1;
  }
  &__content {
    &__bundles {
      background-color: transparent;
    }
    &__body {
      a {
        color: $color3;
        &:hover {
          color: $color1;
        }
      }
    }
    &__wrapper,
    &__accessories,
    &__product-serie,
    &__others-are-looking-at,
    &__iu {
      background-color: $colorBg1;
      @media (--tablet-and-above) {
        border: none;
      }
    }
    &__wrapper {
      background-color: $color5;
    }
    &__iu {
      display: none;
      background-color: transparent !important;
    }
    &__toggle {
      fill: $color3;
    }
  }

  &__part-of-bundle .custom-control-description,
  &__splash .custom-control-description,
  &__volume-discount .custom-control-description {
    font-weight: 700;
  }
  &__part-of-bundle .custom-control-description span,
  &__splash .custom-control-description span,
  &__volume-discount .custom-control-description span {
    font-weight: 400;
  }
  &__maininfo {
    .drop-down {
      border-color: $color5;
      &:hover {
        .drop-down__see-more-header {
          color: $color3;
          background-color: $color5;
        }
      }
    }
  }

  &__part-of-bundle,
  &__splash,
  &__volume-discount {
    .product-card__volume-discount__offer .product-card__savings {
      @media (--mobile) {
        height: 20px;
      }
    }
  }
}
