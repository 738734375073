.topbanners {
  & .arrow,
  .arrow.blueArrow {
    border-color: $color5;
    svg {
      fill: $color5;
    }
    &:hover {
      background-color: $color5;
      border-color: $color5;
      svg {
        fill: $color6;
      }
    }
  }
}
.landingpage {
  &__topbanner,
  &__videotopbanner {
    background-color: $colorBg10;
    &__caption {
      @media (--tablet-lg) {
        padding-left: 110px;
        padding-right: 110px;
      }
      @media (--tablet-sm) {
        padding-left: 75px;
        padding-right: 75px;
      }
      @media (--tablet-xs) {
        padding-left: 70px;
        padding-right: 70px;
      }
      @media (--mobile) {
        padding: 15px 15px 30px 15px;
      }
      
      &--dark {
        .heading--1,
        h1,
        p {
          color: $color3;
        }
      }

      &--half-width {
        @media (--tablet-and-above) {
          padding-top: 15px;
          padding-bottom: 15px;
        }
        @media (--tablet-sm) {
          padding: 30px;
        }
        h1,
        .heading--1,
        p,
        .landingpage__topbanner__button {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    &__nav {
      left: 40px !important;
      border-radius: var(--rounded-corners-lg);
      box-shadow: none !important;
      background-color: rgba(54, 54, 54, 0.7);
      padding: 20px 21px 20px 19px;
      @media (--tablet-lg) {
        left: 30px !important;
        padding: 18px 19px 18px 17px;
      }
      @media (--tablet-sm-and-less) {
        border-radius: var(--rounded-corners);
        left: 15px !important;
        padding: 15px 16px 15px 14px;
      }
      @media (--tablet-xs) {
        padding: 13px 14px 13px 12px;
      }
      &:hover {
        background-color: var(--brand-color);
        background-color: rgba(80, 200, 119, 0.9);
        svg {
          fill: var(--white);
        }
      }
      svg {
        height: 15px;
        width: 15px;
        fill: var(--white);
      }
      &--next {
        left: auto !important;
        right: 40px !important;
        padding: 20px 19px 20px 21px;
        @media (--tablet-lg) {
          right: 30px !important;
          padding: 18px 17px 18px 19px;
        }
        @media (--tablet-sm-and-less) {
          right: 15px !important;
          padding: 16px 15px 16px 17px;
        }
        @media (--tablet-xs) {
          padding: 13px 12px 13px 14px;
        }
      }
    }
  }
}
