.form__label-container {
  font-weight: 700;
}
.customercenter {
  width: 100%;
  padding: 40px 0 60px 0;
  &__top {
    padding-top: 5px;
  }

  @media (--mobile) {
    padding: 10px 0 25px 0;
  }
  .employee {
    width: 25%;
    text-align: center;
    margin-bottom: 40px;
    @media (--mobile) {
      width: calc(25vw - 18px);
      margin: 0 5px 15px 5px;
    }
    @media only screen and (max-width: 549px) {
      width: calc(50vw - 25px);
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      @media (--mobile) {
        padding-top: 10px;
      }
    }
    &__img {
      width: 180px;
      height: 180px;
      margin: 0 auto 15px auto;
      img {
        border-radius: 40%;
      }
      @media (--tablet) {
        width: 170px;
        height: 170px;
      }
      @media (--mobile) {
        height: calc(25vw - 18px);
        width: 100%;
        max-width: 140px;
        max-height: 140px;
        margin: 0 auto 7px auto;
      }
      @media only screen and (max-width: 549px) {
        height: calc(50vw - 25px);
        width: 100%;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      line-height: 26px;
      @media (--mobile) {
        font-size: 13px;
        line-height: 19px;
      }
      span {
        font-weight: 300;
      }
    }
  }
  &__contact {
    &__option {
      width: 25%;
      text-align: center;

      @media (--mobile) {
        width: calc(25vw - 18px);
        margin: 10px auto;
      }
      @media only screen and (max-width: 549px) {
        width: calc(50vw - 35px);
      }
      a {
        text-decoration: none;
        &:focus {
          color: $color5;
        }
      }
      .circle {
        border-radius: 40%;
        background-color: $color1;
        transition: bottom 0.4s ease-in;
        width: 180px;
        height: 180px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color5;
        font-weight: 700;
        flex-direction: column;
        padding: 30px;
        font-size: 20px;
        line-height: 28px;
        cursor: pointer;
        overflow: hidden;
        position: relative;

        @media (--tablet) {
          width: 170px;
          height: 170px;
        }
        @media (--mobile) {
          height: calc(25vw - 18px);
          width: 100%;
          max-width: 140px;
          max-height: 140px;
          transition: 0s;
        }
        @media only screen and (max-width: 549px) {
          height: calc(50vw - 35px);
          width: 100%;
        }
        &:before {
          content: "";
          background-color: $color9;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -100%;
          height: 100%;
          transition: bottom 0.4s ease-in;
          @media (--mobile) {
            transition: 0s;
          }
        }
        span {
          z-index: 1;
          font-size: 18px;
          line-height: 26px;
          @media (--tablet) {
            font-size: 17px;
            line-height: 25px;
          }
          @media (--mobile) {
            font-size: 14px;
            line-height: 20px;
          }
        }
        svg {
          z-index: 2;
          height: 20px;
          width: 20px;
          margin: 0 0 10px 0;
          fill: $color1;
        }
        &:hover {
          transition: bottom 0.4s ease-out;
          @media (--mobile) {
            transition: 0s;
          }
          svg {
            fill: $color5;
          }
          span {
            transition: all 0s linear;
          }

          &:before {
            bottom: 0;
            transition: bottom 0.4s ease-out;
            @media (--mobile) {
              transition: 0s;
            }
          }
        }
      }
      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        @media (--mobile) {
          padding-top: 0;
        }
      }
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      line-height: 30px;
      width: 100%;
      margin-top: 15px;
      .column {
        width: 33%;
        @media (--mobile) {
          width: 50%;
          font-size: 14px;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 449px) {
          width: 100%;
        }
      }
    }
  }
  &__form {
    max-width: 852px;
    margin: 0 auto 40px auto;

    @media (--tablet) {
      margin: 0 auto 30px auto;
    }
    @media (--mobile) {
      margin: 0 auto 15px auto;
    }

    .form-group {
      label {
        font-weight: 700;
        @media (--mobile) {
          font-size: 15px;
        }
      }
      .form-control {
        font-size: 0.9rem;
        padding: 0.85rem;
        height: 46px;
      }
      textarea {
        min-height: 200px;
        height: auto;
      }
    }

    &__wrapper {
      h2 {
        margin-bottom: 20px;
      }
    }
  }
}
