.landingpage .footer-container {
  background-color: $colorBg1;
}

.footer {
  &__top,
  &__usp,
  &__mid {
    background-color: $color9;

    @media (--mobile) {
      .heading--3 {
        font-size: 16px;
      }
      .heading--news-letter {
        font-size: 22px;
      }
    }
    .row {
      justify-content: flex-start;
    }
  }
  &__top {
    @media (--mobile) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__contact__block {
    @media (--tablet-sm) {
      margin-right: 30px;
    }
  }
  &__contact--icon {
    border-color: var(--brand-color);

    svg {
      stroke: $color5;
      stroke-width: 1.5px;

    }
  }
  .contact-block__title {
    text-transform: initial;
    font-weight: 700;
  }
  &__usp {
    @media (--mobile) {
      border: none;
    }
  }
  .container {
    border-color: $color1;
  }

  &__bot {
    background-color: var(--offset-of-brand-color);
    border-top-color: var(--brand-color);
  }
  &__usp a,
  &__usp .element,
  .heading--news-letter {
    text-transform: initial;
    @media (--desktop) {
      font-size: 26px;
    }
    @media (--tablet) {
      font-size: 24px;
    }
    @media (--tablet-xs) {
      font-size: 22px;
    }
  }
  &__usp {
    &__item {
      border-color: var(--brand-color);
      background-color: var(--brand-color);
      small {
        font-weight: 700;
      }
    }
    > .container > div {
      justify-content: left;
    }
  }
  &__list {
    border-color: $color1;
    &-item {
      margin-left: 0;
    }
  }
  &__list-section {
    .heading {
      border: none;
      padding-bottom: 0;
      margin-bottom: 10px;
      @media (--mobile) {
        margin: 0;
        padding: 5px 0;
      }
    }
  }
  &__nav-icon {
    @media (--mobile) {
      top: 10px;
      right: 5px;
    }
  }
  .footer__list-section.active .icon--arrow--down {
    @media (--mobile) {
      right: 10px;
      top: 15px;
    }
  }
  .footer__list-item .footer__nav-link,
  .footer__nav-link {
    &:hover {
      text-decoration: underline;
      color: $color5;
    }
  }
  a,
  a:hover,
  a:focus {
    color: $color5;
  }

  &__news-letter {
    border-color: $color1;
    background-color: transparent;
    @media (--mobile) {
      border: none;
    }
    .btn {
      text-transform: initial;
      border-radius: 14px;
      background-color: $color3;
      font-size: 14px;
      &:hover {
        background-color: rgba(54, 54, 54, 0.7);
      }
    }
  }
  &__logo-img {
    width: 200px;
    height: 40px;
  }
}
